import { environment } from '@env/environment';
import { formatter } from '../utils/url-formatter';

// Base URLs
export const BASE = environment.haulmatic.baseUrl;
export const ZUUL = environment.haulmatic.apiGateway;

// GraphQL Endpoints
export const GRAPHQL = `${ZUUL}/api/graphql`;
export const FILE_CONVERTER = `${ZUUL}/api/file-converter`;
export const FILE_UPLOAD = `${BASE}/api/file-handler/v1/files`;

// Authentication and Organization Endpoints
const AUTH = `${BASE}/auth`;

const FILE_PARSER = `${BASE}/api/file-parser`;

// API Endpoints
export const API = {
  auth: {
    getToken: `${AUTH}/users/login`,
    refreshToken: `${AUTH}/users/refresh`,
    getUserByUserId: formatter(`${AUTH}/identity/{0}`),
    getTenants: `${AUTH}/tenant-details`,
    resetPasswordInit: `${AUTH}/users/reset-password/init`,
    resetPassword: `${AUTH}/users/reset-password/reset`,
  },
  fileParser: {
    processBulkOrders: `${FILE_PARSER}/process-file/process-bulk-orders`,
  },
};
