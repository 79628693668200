import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { doesHaveOneOfScopes, SCOPES } from '@configs/scopes';
import { AttachmentModel } from '@core/models/attachment.model';
import { AuthService } from '@core/services/auth/auth.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Operation } from 'app/modules/organizations-manager/models/operation.model';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RefreshTokenModel } from '../../models/refresh-token.model';
import { User } from '../../models/user.model';
import { SetSpinner } from '../shared/shared.actions';
import {
  AutoLoginAction,
  AutoLoginFailed,
  AutoLoginSuccess,
  Login,
  LoginFailed,
  LoginSuccess,
  Logout,
  RefreshToken,
  ResetLoginMessage,
  SetToken,
  SetUser,
} from './auth.actions';

export const authStateKey = 'auth';
const ONE_MILLISECOND = 1000;
export class AuthStateModel {
  public token: string;
  public user: User;
  public message: string;
  public error: boolean;
  public logo: AttachmentModel;
  public currentUserOperations: Operation[];
  public selectedOperation: Operation;
}

@State<AuthStateModel>({
  name: authStateKey,
  defaults: {
    token: '',
    user: null,
    message: '',
    error: false,
    logo: null,
    currentUserOperations: [],
    selectedOperation: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static getUserFullName(state: AuthStateModel): string {
    return state.user?.firstName + ' ' + state.user?.lastName;
  }

  @Selector()
  static getLogo(state: AuthStateModel): AttachmentModel {
    return state.logo;
  }

  @Selector()
  static getOrgId(state: AuthStateModel): string {
    return state.user?.orgId;
  }

  @Selector()
  static getOrgUserId(state: AuthStateModel): string {
    return state.user?.userId;
  }

  @Selector()
  static getErrorMessage(state: AuthStateModel): string | null {
    return state.message || null;
  }

  @Selector()
  static hasError(state: AuthStateModel): boolean {
    return state.error;
  }

  @Selector()
  static getCurrentUserOperations(state: AuthStateModel): Operation[] {
    console.log(`currentUserOperations: ${state.currentUserOperations}`);
    return [...state.currentUserOperations];
  }

  // this used to get user operation names by ids
  @Selector()
  static getCurrentOperations(state: AuthStateModel): string[] {
    return [...state.user.operations];
  }

  @Selector()
  static getSelectedOperation(state: AuthStateModel): Operation {
    if (state.user.operations.length > 1) {
      return { _id: '', name: 'All' };
    }
    return state.currentUserOperations.find(op => op._id === state.user?.operations[0]);
  }

  @Selector()
  static getUserScopes(state: AuthStateModel) {
    return state.user.scopes;
  }

  @Selector()
  static getIsSuperAdmin(state: AuthStateModel) {
    return doesHaveOneOfScopes(state.user.scopes, [SCOPES.ADMIN.SUPER]);
  }

  @Action(SetUser)
  setUser({ patchState }: StateContext<AuthStateModel>, { user }: SetUser) {
    return of(patchState({ user }));
  }

  @Action(SetToken)
  setToken({ patchState }: StateContext<AuthStateModel>, { token }: SetToken) {
    patchState({ token });
  }

  @Action(AutoLoginAction)
  autoLogin({ dispatch, patchState }: StateContext<AuthStateModel>) {
    patchState({ token: null, user: null, message: null, logo: null, error: false });
    const token = this.cookieService.get('token');
    if (!token) {
      dispatch([new AutoLoginFailed(), new SetSpinner(false)]);
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      const exp = decodedToken[`exp`] as number;

      if (exp && Date.now() > exp * ONE_MILLISECOND) {
        console.log('token expired, Auto login failed');
        dispatch([new AutoLoginFailed(), new SetSpinner(false)]);
      }
      // auto login success
      const user: User = decodedToken as User;
      user.userId = decodedToken[`orgUserId`];
      // TODO: remove this, and get it from the token, currently this is not in the token
      patchState({ token, user, message: 'Login Successful', logo: null });
      dispatch([new AutoLoginSuccess(), new SetSpinner(false)]);
    } catch (_e) {
      dispatch([new AutoLoginFailed(), new SetSpinner(false)]);
    }
  }

  @Action(Login)
  login({ dispatch, patchState }: StateContext<AuthStateModel>, loginAction: Login) {
    dispatch(new SetSpinner(false));
    return this.authService.login(loginAction).pipe(
      tap(loginResponse => {
        this.handleLoginToken(loginResponse, patchState);
        dispatch([new LoginSuccess(loginAction.redirectUrl), new SetSpinner(false)]);
      }),
      catchError(err => {
        return dispatch([new LoginFailed(err.message)]);
      })
    );
  }

  @Action(RefreshToken)
  refresh({ dispatch, patchState }: StateContext<AuthStateModel>, action: RefreshToken) {
    dispatch(new SetSpinner(true));
    const refreshToken = localStorage.getItem('refreshToken');
    const tokenRequest: RefreshTokenModel = { refreshToken, selectedOperation: action.operation?._id };

    return this.authService.refreshToken(tokenRequest).pipe(
      tap(loginResponse => {
        this.handleLoginToken(loginResponse, patchState);
        window.location.reload();
      }),
      catchError(err => {
        // TODO: Give a good login message
        dispatch([new LoginFailed(err.message)]);
        return dispatch(new SetSpinner(false));
      })
    );
  }

  handleLoginToken(loginResponse, patchState: (val: Partial<AuthStateModel>) => AuthStateModel) {
    const decodedToken = jwtDecode(loginResponse.accessToken);
    const user: User = decodedToken as User;
    user.userId = decodedToken[`orgUserId`];
    // TODO: remove this, and get it from the token, currently this is not in the token
    // user.designation = ResourceType.FLEETMANAGER;
    // TODO: TEMPORARILY ADD ADMIN_SCOPE
    // user.scopes = [...user.scopes, ADMIN_SCOPE];

    patchState({
      token: loginResponse.accessToken,
      user,
      message: 'Login Successful',
      logo: null,
    });
    this.cookieService.deleteAll('token');
    this.cookieService.set('token', loginResponse.accessToken, decodedToken[`exp`] * ONE_MILLISECOND, '/');
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('refreshToken', loginResponse.refreshToken);
  }

  @Action(LoginFailed)
  processLoginFailure({ dispatch, patchState }: StateContext<AuthStateModel>) {
    dispatch([new SetSpinner(false)]);
    return patchState({
      token: null,
      user: null,
      error: true,
      message: 'Username or Password Incorrect, Please try again',
      logo: null,
    });
  }

  @Action(ResetLoginMessage)
  resetLoginMessage({ patchState }: StateContext<AuthStateModel>) {
    return patchState({ message: null, error: false });
  }

  @Action(Logout)
  logout({ patchState }: StateContext<AuthStateModel>) {
    this.cookieService.deleteAll();
    localStorage.clear();
    patchState({
      message: null,
      token: null,
      user: null,
      logo: null,
      error: false,
    });
    this.cookieService.deleteAll('/');
    localStorage.clear();
    console.log('Local storage cleared');
  }

  /*
  @Action(PasswordResetRequestAction)
  sendPasswordResetRequest({ dispatch }: StateContext<AuthStateModel>, { userId }: PasswordResetRequestAction) {
    return this.authService.initResetPassword(userId).pipe(
      map(r => {
        console.log(r);
        return dispatch(new SetNotificationAction('Successfully Initialized Reset Password', AlertDialogType.SUCCESS));
      }),
      catchError(_err =>
        dispatch(new SetNotificationAction('Error Initializing Reset Password', AlertDialogType.ERROR))
      )
    );
  }

  @Action(PasswordResetAction)
  resetPassword(
    { dispatch }: StateContext<AuthStateModel>,
    { newPassword, reTypeNewPassword, resetKey }: PasswordResetAction
  ) {
    return this.authService.resetPassword({ newPassword, confirmPassword: reTypeNewPassword, resetKey }).pipe(
      map(r => {
        console.log(r);
        return dispatch(new SetNotificationAction('Successfully Reset Password', AlertDialogType.SUCCESS));
      }),
      catchError(_err => dispatch(new SetNotificationAction('Error Resetting Password', AlertDialogType.ERROR)))
    );
  } */
}
