import { getUsersSystemsWideAllowedActions, isActionValidSystemWide } from '@core/guards/auth.guard';

const ORGS = 'orgs';
const PARTNER_ORGS = 'partner_orgs';
const CLUSTERS = 'clusters';
const OPERATIONS = 'operations';
const HUMAN_RESOURCES = 'human_resources';
const VEHICLES = 'vehicles';
const LOCATIONS = 'locations';
const DOCUMENTS = 'documents';

const JOB_FILES = 'job_files';
const TENDERS = 'tenders';

const SYSTEMS_WIDE = 'systems_wide';

type ScopePrefixes =
  | typeof ORGS
  | typeof PARTNER_ORGS
  | typeof CLUSTERS
  | typeof OPERATIONS
  | typeof HUMAN_RESOURCES
  | typeof VEHICLES
  | typeof LOCATIONS
  | typeof DOCUMENTS
  | typeof SYSTEMS_WIDE
  | typeof JOB_FILES
  | typeof TENDERS;

const COMMON_ACTIONS = ['read', 'create', 'update', 'delete', 'any'] as const;

type CommonActions = (typeof COMMON_ACTIONS)[number];
type CapitalizedCommonActions = Uppercase<CommonActions>;

function getScopesWithCommonActions(prefix: ScopePrefixes) {
  return Object.fromEntries(COMMON_ACTIONS.map(action => [action.toUpperCase(), `${prefix}:${action}`])) as Record<
    CapitalizedCommonActions,
    string
  >;
}

/**
 * This is the map of scopes that the user can have.
 * @example - If a user has the scope 'SCOPES.ORGS.READ' (orgs:read), they can view organizations.
 */
export const SCOPES = {
  ORGS: getScopesWithCommonActions(ORGS),
  PARTNER_ORGS: getScopesWithCommonActions(PARTNER_ORGS),
  CLUSTERS: getScopesWithCommonActions(CLUSTERS),
  OPERATIONS: getScopesWithCommonActions(OPERATIONS),
  HUMAN_RESOURCES: getScopesWithCommonActions(HUMAN_RESOURCES),
  VEHICLES: getScopesWithCommonActions(VEHICLES),
  // if need to add custom scopes to a specific prefix, add them here like this
  LOCATIONS: {
    ...getScopesWithCommonActions(LOCATIONS),
    // add custom scopes here
    // example:
    // PROMOTE: 'locations:promote',
  },
  DOCUMENTS: getScopesWithCommonActions(DOCUMENTS),
  SYSTEMS_WIDE: getScopesWithCommonActions(SYSTEMS_WIDE),
  JOB_FILES: {
    ...getScopesWithCommonActions(JOB_FILES),
    TERMINATE: 'job_files:terminate',
  },
  TENDERS: getScopesWithCommonActions(TENDERS),

  // YOU CAN ADD MORE CUSTOM SCOPES THAT DON'T FIT INTO THE COMMON ACTIONS IN THIS WAY
  ADMIN: { SUPER: 'admin:super' },
} as const;

/**
 * Checks if the user has the required scopes
 * @param user - The user to check
 * @param oneOfScopes - The scopes to check for
 * @returns - True if the user has the required scopes, false otherwise
 */
export function doesHaveOneOfScopes(userScopes: string[], oneOfScopes: string[]) {
  const usersSystemWideAllowedActions = getUsersSystemsWideAllowedActions(userScopes);
  return oneOfScopes.some(
    scope => userScopes.includes(scope) || isActionValidSystemWide(scope.split(':')[1], usersSystemWideAllowedActions)
  );
}

/**
 * Checks if the user has all of the required scopes
 * @param user - The user to check
 * @param allOfScopes - The scopes to check for
 * @returns - True if the user has all of the required scopes, false otherwise
 */
export function doesHaveAllOfScopes(userScopes: string[], allOfScopes: string[]) {
  const usersSystemWideAllowedActions = getUsersSystemsWideAllowedActions(userScopes);
  return allOfScopes.every(
    scope => userScopes.includes(scope) || isActionValidSystemWide(scope.split(':')[1], usersSystemWideAllowedActions)
  );
}
