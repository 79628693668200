import { Injectable } from '@angular/core';
import { JOB_UPDATES_STATE_TOKEN } from '@core/store/job-updates/job-updates.state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EntityMap } from '@shared/types/entity-map.type';
import { Apollo, gql } from 'apollo-angular';
import { catchError, of, tap } from 'rxjs';
import { FourPlContract } from '../models/4pl-contract.model';
import { FourPLCustomer } from '../models/4pl-customer.model';
import { JobFile } from '../models/job-file.model';
import { Order } from '../models/order.model';
import { ServiceCard } from '../models/service-card/service-card.model';
import { ServiceConfig } from '../models/service-config.model';
import { ServiceOption } from '../models/service-option.model';
import { Shipment } from '../models/shipment.model';
import {
  AddHmtActivity,
  AddOrdersToShipment,
  CreateDefaultServices,
  CreateOrder,
  CreateShipment,
  DeleteOrder,
  DeselectService,
  DeselectShipment,
  DuplicateServiceOption,
  Fetch4PLContracts,
  Fetch4PLCustomers,
  FetchAllOrders,
  FetchAllShipments,
  FetchAssignedServices,
  FetchJobFile,
  FetchPaginatedCategorizedOrders,
  FetchPaginatedOrders,
  FetchServiceCard,
  FetchShipmentsByIds,
  RemoveHmtActivity,
  RemoveOrderFromShipment,
  RemoveServiceCard,
  RemoveServiceOption,
  RemoveShipmentWithReference,
  ResetOrderDocuments,
  ResetWizard,
  SaveJobFile,
  SaveJobFileWithTenderRequest,
  SelectOrder,
  SelectService,
  SelectShipment,
  Set4PLCustomer,
  Set4PlContract,
  UpdateActivity,
  UpdateDefaultServices,
  UpdateJobSmartenedInJobFile,
  UpdateServiceCard,
  UpdateServiceCardTab,
  UploadOrderDocument,
} from './wizard.actions';

// Import the shared queries and mutations
import { GraphqlClientService } from '@core/services/graphql-client.service';
import {
  ADD_HMT_ACTIVITY_MUTATION,
  ADD_ORDERS_TO_SHIPMENT_MUTATION,
  CREATE_DEFAULT_SERVICES_MUTATION,
  CREATE_JOB_FILE_MUTATION,
  CREATE_JOB_FILE_WITH_TENDER_REQUEST_MUTATION,
  CREATE_ORDER_MUTATION,
  CREATE_SHIPMENT_MUTATION,
  DELETE_ORDER_TAB_ORDER_MUTATION,
  DUPLICATE_SERVICE_OPTIONS_MUTATION,
  FETCH_ALL_ORDERS_QUERY,
  FETCH_ALL_SHIPMENTS_QUERY,
  FETCH_ASSIGNED_SERVICES_QUERY,
  FETCH_JOB_FILE_QUERY,
  FETCH_SERVICE_CARD_QUERY,
  FETCH_SHIPMENTS_BY_IDS_QUERY,
  GET_PAGINATED_ORDERS_BY_JOB_REF_ID_QUERY,
  PAGINATED_ORDERS_WITH_VALIDATION_QUERY,
  REMOVE_HMT_ACTIVITY_MUTATION,
  REMOVE_ORDER_FROM_SHIPMENT_MUTATION,
  REMOVE_SERVICE_CARD_MUTATION,
  REMOVE_SERVICE_OPTION_MUTATION,
  REMOVE_SHIPMENT_WITH_REFERENCE_MUTATION,
  UPDATE_ACTIVITY_MUTATION,
  UPDATE_DEFAULT_SERVICES_MUTATION,
  UPDATE_JOB_SMARTENED_IN_JOB_FILE_MUTATION,
  UPDATE_ORDER_MUTATION,
  UPDATE_SERVICE_CARD_MUTATION,
} from '@shared/gql-shared-queries';
import { Pageable } from '@shared/models/pageable.model';
import { Activity } from '../models/activity.model';
import { OrderPageable } from '../models/order-pageable.model';
import { ShippingDocument } from '../models/shipping-document.model';
import { UploadedFileType } from '../models/uploaded-file.type';

//TODO: add 2 seperate child states as orders and shipments to breakdown the file
export class WizardStateModel {
  jobFile: JobFile;
  orders: EntityMap<string, Order>;
  shipments: EntityMap<string, Shipment>;
  serviceCard: ServiceCard;
  // added this because when the activities are updated, whole serviceCard object is mutated which is results in
  //emitting values to the service card selector and it is used in many places that doesn't require updates when
  //activities are updated
  serviceCardActivities: Activity[];
  assignedServices: EntityMap<string, ServiceConfig>;
  selectedServicesByShipmentId: EntityMap<string, string[]>;
  selectedOrder: Order;
  fourPLCustomers: FourPLCustomer[];
  selectedFourPLCustomer: FourPLCustomer;
  fourPlContracts: FourPlContract[];
  selectedFourPlContract: FourPlContract;
  orderDocuments: EntityMap<string, UploadedFileType[]>;
  categorizedOrders: EntityMap<string, Pageable<Order>[]>;
  paginatedOrders: OrderPageable;
}

const defaults = {
  //TODO: remove this. this is used to  display orders in the orders tab in initial development, but its now usee
  //in tendering and bidding modules as well. remove the dependencies and remove this
  orders: {},
  jobFile: null,
  shipments: {},
  serviceCard: null,
  assignedServices: {},
  selectedServicesByShipmentId: {},
  selectedOrder: null,
  fourPLCustomers: [],
  selectedFourPLCustomer: null,
  fourPlContracts: [],
  selectedFourPlContract: null,
  orderDocuments: {},
  serviceCardActivities: [],
  categorizedOrders: {},
  paginatedOrders: {
    shipmentOrderCount: 0,
    items: [],
    pageInfo: {
      total: 0,
      pageSize: 0,
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
};

@State<WizardStateModel>({
  name: 'wizard',
  defaults,
})
@Injectable()
export class WizardState {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private graphqlClient: GraphqlClientService
  ) {}

  @Selector()
  static getOrders(state: WizardStateModel) {
    return Object.values(state.orders);
  }

  @Selector()
  static getPaginatedOrders(state: WizardStateModel) {
    return state.paginatedOrders;
  }

  @Selector()
  static getJobFile(state: WizardStateModel) {
    return state.jobFile;
  }

  @Selector()
  static getShipments(state: WizardStateModel) {
    return Object.values(state.shipments);
  }

  @Selector()
  static getServiceCard(state: WizardStateModel) {
    return state.serviceCard;
  }

  @Selector()
  static getShipmentServiceOptions(state: WizardStateModel): (shipmentId: string) => ServiceOption[] {
    return (shipmentId: string): ServiceOption[] => {
      return state.shipments[shipmentId]?.serviceOptions || [];
    };
  }

  @Selector()
  static getSelectedServicesByShipmentId(state: WizardStateModel): EntityMap<string, string[]> {
    return state.selectedServicesByShipmentId;
  }

  @Selector()
  static getSelectedOrder(state: WizardStateModel) {
    return state.selectedOrder;
  }
  @Selector()
  static getAssignedServices(state: WizardStateModel) {
    return Object.values(state.assignedServices);
  }

  @Selector()
  static getServiceCardActivities(state: WizardStateModel) {
    return state.serviceCardActivities;
  }

  @Selector()
  static getOrderDocuments(state: WizardStateModel): EntityMap<string, UploadedFileType[]> {
    if (state.selectedOrder && Array.isArray(state.selectedOrder.shippingDocuments)) {
      const shippingDocs =
        state.selectedOrder?.shippingDocuments?.reduce(
          (acc, curr: ShippingDocument) => {
            acc[curr.category] = curr?.documentDetails || [];
            return acc;
          },
          {} as EntityMap<string, UploadedFileType[]>
        ) || {};

      return {
        ...shippingDocs,
        ...state.orderDocuments,
      };
    }
    return state?.orderDocuments;
  }

  @Selector()
  static getCategorizedOrders(state: WizardStateModel): (shipmentId: string) => Pageable<Order>[] {
    return (shipmentId: string): Pageable<Order>[] => {
      return state.categorizedOrders[shipmentId] || [];
    };
  }

  @Action(SaveJobFileWithTenderRequest)
  saveJobFileWithTenderRequest(
    { patchState }: StateContext<WizardStateModel>,
    { jobFile, parentJobRefId, tenderId }: SaveJobFileWithTenderRequest
  ) {
    const { currentOperation } = this.store.selectSnapshot(state => state.app);
    const {
      user: { orgId },
    } = this.store.selectSnapshot(state => state.auth);
    const operationId = typeof currentOperation === 'string' ? currentOperation : currentOperation?._id || '';
    const variables = {
      input: {
        orgId: orgId ?? '',
        title: jobFile?.title ?? '',
        operationId: operationId ?? '',
        jobGroupId: jobFile?.jobGroupId ?? '',
        workflowId: jobFile?.workflowId ?? '',
        customerReferenceNo: jobFile?.customerReferenceNo ?? '',
        tenderId: tenderId ?? '',
        parentJobRefId: parentJobRefId ?? '',
      },
    };

    return this.apollo.mutate({ mutation: CREATE_JOB_FILE_WITH_TENDER_REQUEST_MUTATION, variables }).pipe(
      tap(response => {
        const jobFile = response.data['createJobFileWithTenderRequest'];
        patchState({
          jobFile,
        });
      }),
      catchError(async err => console.error('Error saving JobFile', err))
    );
  }

  @Action(SaveJobFile)
  createJobFile({ patchState }: StateContext<WizardStateModel>, { jobFile }: SaveJobFile) {
    const {
      user: { orgId },
    } = this.store.selectSnapshot(state => state.auth);

    const variables = {
      input: {
        orgId: orgId ?? '',
        title: jobFile?.title ?? '',
        operationId: jobFile?.operationId ?? '',
        jobGroupId: jobFile?.jobGroupId ?? '',
        workflowId: jobFile?.workflowId ?? '',
        customerReferenceNo: jobFile?.customerReferenceNo ?? '',
        onBehalfOf: jobFile?.onBehalfOf ?? false,
        onBehalfOfCustomerId: jobFile?.onBehalfOfCustomerId ?? null,
        onBehalfOfContractId: jobFile?.onBehalfOfContractId ?? null,
      },
    };

    return this.apollo
      .mutate({
        mutation: CREATE_JOB_FILE_MUTATION,
        variables: variables,
      })
      .pipe(
        tap(response => {
          const createdJobFile = response.data['createJobFile'];
          patchState({
            jobFile: createdJobFile,
          });
        }),
        catchError(err => {
          console.error('Error saving JobFile', err);
          return of(null);
        })
      );
  }

  @Action(FetchJobFile)
  fetchJobfile({ patchState }: StateContext<WizardStateModel>, { jobRefId }: FetchJobFile) {
    if (!jobRefId) {
      return of(null);
    }
    return this.apollo
      .query({
        query: FETCH_JOB_FILE_QUERY,
        variables: { jobRefId },
      })
      .pipe(
        tap(response => {
          const jobFile = response.data['findByJobRefId'];
          patchState({
            jobFile,
          });
        }),
        catchError(err => {
          console.error('Error Fetching JobFile', err);
          return of(null);
        })
      );
  }

  @Action(CreateOrder)
  async saveOrder({ getState }: StateContext<WizardStateModel>, { order }: CreateOrder) {
    const { currentTransactionId } = await this.store.selectSnapshot(JOB_UPDATES_STATE_TOKEN);

    const mappedOrderDocuments: ShippingDocument[] = [];
    const orderDocuments = getState()?.orderDocuments;
    if (orderDocuments && typeof orderDocuments === 'object' && Object.keys(orderDocuments).length > 0) {
      Object.entries(orderDocuments).forEach(([category, documentDetails]) => {
        if (documentDetails && documentDetails.length > 0) {
          mappedOrderDocuments.push({
            category,
            documentDetails,
          });
        }
      });
    }

    const variables = {
      id: order?._id,
      input: {
        orderRefNumber: order?.orderRefNumber ?? '',
        parties: {
          notifyingParties:
            order?.parties?.notifyingParties?.map(result =>
              typeof result === 'string' ? result : result?.value ?? ''
            ) ?? [],
          sellerId: order?.parties?.sellerId?.value ?? '',
          buyerId: order?.parties?.buyerId?.value ?? '',
        },
        keyDates: {
          plannedProductionCompletionDate: order?.keyDates['plannedProductionCompletionDate']
            ? order?.keyDates['plannedProductionCompletionDate'] instanceof Date
              ? new Date(order?.keyDates['plannedProductionCompletionDate'])
              : order?.keyDates['plannedProductionCompletionDate']
            : '',
          plannedCargoReadyDate: order?.keyDates?.['plannedCargoReadyDate']
            ? order?.keyDates?.['plannedCargoReadyDate'] instanceof Date
              ? new Date(order?.keyDates?.['plannedCargoReadyDate'])
              : order?.keyDates?.['plannedCargoReadyDate']
            : '',
          lastDateOfShipment: order?.keyDates?.['lastDateOfShipment']
            ? order?.keyDates?.['lastDateOfShipment'] instanceof Date
              ? new Date(order?.keyDates?.['lastDateOfShipment'])
              : order?.keyDates?.['lastDateOfShipment']
            : '',
          inHouseReadyDate: order?.keyDates?.['inHouseReadyDate']
            ? order?.keyDates?.['inHouseReadyDate'] instanceof Date
              ? new Date(order?.keyDates?.['inHouseReadyDate'])
              : order?.keyDates?.['inHouseReadyDate']
            : '',
        },
        shipmentDetails: {
          cargoPickupLocation: order?.shipmentDetails?.cargoPickupLocation?.value ?? '',
          cargoDropOffLocation: order?.shipmentDetails?.cargoDropOffLocation?.value ?? '',
          countryOfDestination: order?.shipmentDetails?.countryOfDestination?.value ?? '',
          countryOfOrigin: order?.shipmentDetails?.countryOfOrigin?.value ?? '',
          incoterm: order?.shipmentDetails?.incoterm?.value ?? '',
          partialShipment: order?.shipmentDetails?.partialShipment ?? false,
          transhipment: order?.shipmentDetails?.transhipment ?? false,
          valueOfCargo: order?.shipmentDetails?.valueOfCargo ?? 0,
          preferredShipmentMode: order?.shipmentDetails?.preferredShipmentMode?.value ?? '',
          paymentMethod: order?.shipmentDetails?.paymentMethod?.value ?? '',
        },
        cargoDetails: {
          grossWeight: order?.cargoDetails?.grossWeight ?? 0,
          netWeight: order?.cargoDetails?.netWeight ?? 0,
          chargeableWeight: order?.cargoDetails?.chargeableWeight ?? 0,
          specialCargo: order?.cargoDetails?.specialCargo ?? false,
          dangerousCargo: order?.cargoDetails?.dangerousCargo ?? false,
          packingList: order?.cargoDetails?.packingList ?? [],
        },
        loadDetails: order?.cargoDetails?.loadDetails ?? [],
        shipmentMethod: order?.shipmentMethod ?? 'FCL',
        shippingDocuments: mappedOrderDocuments,
      },
    };

    if (order?._id) {
      variables.id = order._id;
      return this.apollo
        .mutate({
          mutation: UPDATE_ORDER_MUTATION,
          variables: variables,
        })
        .pipe(
          tap(response => {
            const order = response.data['updateOrder'];
            console.log('order successfully Updated:', order);
          }),
          catchError(err => {
            console.error('Error Updating order', err);
            return of(null);
          })
        );
    }

    variables.input['jobRefId'] = getState().jobFile.jobRefId;
    variables.input['orgId'] = getState().jobFile.orgId;
    variables.input['transactionId'] = currentTransactionId;

    return this.apollo
      .mutate({
        mutation: CREATE_ORDER_MUTATION,
        variables: variables,
      })
      .pipe(
        tap(response => {
          const order = response.data['createOrder'];
          console.log('order successfully saved:', order);
        }),
        catchError(err => {
          console.error('Error saving order', err);
          return of(null);
        })
      );
  }

  //TODO: remove this. this is used to  display orders in the orders tab in initial development, but its now usee
  //in tendering and bidding modules as well. remove the dependencies and remove this
  @Action(FetchAllOrders)
  fetchAllOrders({ patchState }: StateContext<WizardStateModel>, { jobRefId }: FetchAllOrders) {
    return this.apollo
      .query<unknown>({
        query: FETCH_ALL_ORDERS_QUERY,
        variables: { jobRefId },
      })
      .pipe(
        tap(response => {
          const orders = response.data['findOrdersByJobRefIdFromReferenceDocuments'];
          patchState({
            orders: orders.reduce((acc, order) => {
              acc[order._id] = order;
              return acc;
            }, {}),
          });
        }),
        catchError(err => {
          console.error('Error fetching orders', err);
          return of(null);
        })
      );
  }

  @Action(FetchPaginatedOrders)
  fetchPaginatedOrders(
    { patchState }: StateContext<WizardStateModel>,
    { jobRefId, offset, limit, filter }: FetchPaginatedOrders
  ) {
    return this.apollo
      .query<typeof GET_PAGINATED_ORDERS_BY_JOB_REF_ID_QUERY>({
        query: GET_PAGINATED_ORDERS_BY_JOB_REF_ID_QUERY,
        variables: { jobRefId, offset, limit, filter },
      })
      .pipe(
        tap(response => {
          const paginatedOrders = response.data['findPaginatedOrdersByJobRefIdFromReferenceDocuments'];
          patchState({
            paginatedOrders: paginatedOrders,
          });
        }),
        catchError(err => {
          console.error('Error fetching paginated orders', err);
          return of(null);
        })
      );
  }

  @Action(CreateShipment)
  async saveShipment({ getState }: StateContext<WizardStateModel>, { shipment }: CreateShipment) {
    const jobFile = getState()?.jobFile;
    const { currentTransactionId } = await this.store.selectSnapshot(JOB_UPDATES_STATE_TOKEN);

    if (!jobFile) {
      return of(null);
    }

    return this.apollo
      .mutate({
        mutation: CREATE_SHIPMENT_MUTATION,
        variables: {
          input: {
            transactionId: currentTransactionId,
            orgId: getState().jobFile.orgId,
            jobRefId: jobFile?.jobRefId,
            shipmentRefNumber: shipment?.shipmentRefNumber ?? '',
          },
        },
      })
      .pipe(
        tap(response => {
          const shipment = response.data['createShipment'];
          console.log('Shipment Created: ', shipment);
        }),
        catchError(err => {
          console.error('Error saving shipments', err);
          return of(null);
        })
      );
  }

  @Action(FetchAllShipments)
  fetchAllShipments({ patchState }: StateContext<WizardStateModel>, { jobRefId }: FetchAllShipments) {
    return this.apollo
      .query<unknown>({
        query: FETCH_ALL_SHIPMENTS_QUERY,
        variables: { jobRefId },
      })
      .pipe(
        tap(response => {
          const findShipmentsByJobRefId = response.data['findShipmentsByJobRefIdFromReferenceDocuments'];
          patchState({
            shipments: findShipmentsByJobRefId.reduce((acc, shipment) => {
              acc[shipment._id] = shipment;
              return acc;
            }, {}),
          });
        }),
        catchError(err => {
          console.error('Error fetching shipments', err);
          return of(null);
        })
      );
  }

  @Action(FetchServiceCard)
  fetchService(
    { patchState }: StateContext<WizardStateModel>,
    { shipmentId, serviceOptionId, serviceId }: FetchServiceCard
  ) {
    return this.apollo
      .query<unknown>({
        query: FETCH_SERVICE_CARD_QUERY,
        variables: { id: serviceId, shipmentId, serviceOptionId },
      })
      .pipe(
        tap(response => {
          const serviceCard = response.data['findServiceCardByShipmentIdAndServiceOptionId'];
          patchState({
            serviceCard: { ...serviceCard },
          });
          patchState({
            serviceCardActivities: serviceCard?.activities ?? [],
          });
        }),
        catchError(err => {
          console.error('Error fetching service card', err);
          return of(null);
        })
      );
  }

  @Action(AddOrdersToShipment)
  async addOrdersToShipment({ getState }: StateContext<WizardStateModel>, { shipmentId, orders }: AddOrdersToShipment) {
    const { currentTransactionId } = await this.store.selectSnapshot(JOB_UPDATES_STATE_TOKEN);
    return this.apollo
      .mutate({
        mutation: ADD_ORDERS_TO_SHIPMENT_MUTATION,
        variables: {
          id: shipmentId,
          input: {
            orderIds: orders.map(order => order._id),
            transactionId: currentTransactionId,
            orgId: getState().jobFile.orgId,
            jobRefId: getState().jobFile?.jobRefId,
          },
        },
      })
      .pipe(
        tap(response => {
          console.log(response);
        }),
        catchError(async err => console.error('Error saving shipments', err))
      );
  }

  @Action(FetchAssignedServices)
  fetchAssignedServices(
    { patchState, getState }: StateContext<WizardStateModel>,
    { shipmentMode }: FetchAssignedServices
  ) {
    return this.apollo
      .query<ServiceConfig[]>({
        query: FETCH_ASSIGNED_SERVICES_QUERY,
        variables: {
          workflowId: getState().jobFile?.workflow?.workflowId,
          shipmentMode,
        },
      })
      .pipe(
        tap(response => {
          const services = response.data['findServicesConfigByWorkflowIdAndShipmentMode'];
          patchState({
            assignedServices: services.reduce((acc, service) => {
              acc[service._id] = service;
              return acc;
            }, {}),
          });
        }),
        catchError(async err => console.error('Error fetching Assigned Services', err))
      );
  }

  @Action(CreateDefaultServices)
  createDefaultServices(
    { getState }: StateContext<WizardStateModel>,
    { selectedServices, shipmentMode, shipmentId }: CreateDefaultServices
  ) {
    return this.apollo
      .mutate({
        mutation: CREATE_DEFAULT_SERVICES_MUTATION,
        variables: {
          orgId: getState().jobFile?.orgId,
          jobRefId: getState().jobFile?.jobRefId,
          workflowDefIds: selectedServices,
          shipmentMode: shipmentMode,
          shipmentId: shipmentId,
        },
      })
      .pipe(
        tap(response => {
          //TODO: dispatch a success notification action
          console.log('create Default services Response', response);
        }),
        catchError(async err => console.error('Error Occurred while generating default services', err))
      );
  }

  @Action(UpdateDefaultServices)
  updateDefaultServices(
    { getState }: StateContext<WizardStateModel>,
    { selectedServices, shipmentMode, shipmentId, serviceOptionId, previousPlannedServiceId }: UpdateDefaultServices
  ) {
    return this.graphqlClient
      .mutation(UPDATE_DEFAULT_SERVICES_MUTATION, {
        orgId: getState().jobFile?.orgId,
        jobRefId: getState().jobFile?.jobRefId,
        workflowDefIds: selectedServices,
        shipmentMode: shipmentMode,
        shipmentId: shipmentId,
        serviceOptionId: serviceOptionId,
        previousPlannedServiceId: previousPlannedServiceId,
      })
      .pipe(
        tap(response => {
          console.log('update Default services Response', response);
        }),
        catchError(async err => console.error('Error Occurred while generating default services', err))
      );
  }

  @Action(UpdateServiceCardTab)
  updateServiceCardTab(
    { getState }: StateContext<WizardStateModel>,
    { tab, plannedServiceId, updatedServiceCard }: UpdateServiceCardTab
  ) {
    const currScreen = this.store.selectSnapshot(state => state.workflow.tempView);
    const editableFields = currScreen?.tabMapping[tab];
    const tabWiseSaveMapping = currScreen?.tabWiseSaveMapping[tab];

    const valuesOfPaths = WizardState.getValuesFromPaths(updatedServiceCard, tabWiseSaveMapping);
    const editableFieldValues = WizardState.replacePaths(valuesOfPaths, editableFields);
    const createdObject = WizardState.createNestedObjectFromPaths(editableFieldValues, editableFields);
    createdObject['tabIndex'] = tab;
    createdObject['screenId'] = currScreen?._id;
    createdObject['plannedServiceId'] = plannedServiceId;
    createdObject['orgId'] = getState().jobFile?.orgId;
    createdObject['jobRefId'] = getState().jobFile?.jobRefId;

    return this.apollo
      .mutate({
        mutation: UPDATE_SERVICE_CARD_MUTATION,
        variables: {
          updateServiceCard: createdObject,
        },
      })
      .pipe(
        tap(response => {
          // const updatedServiceCard = response.data['updateServiceCard'];
          // patchState({
          //   serviceCard: { ...updatedServiceCard },
          // });
        }),
        catchError(async err => console.error('Error Occurred while generating default services', err))
      );
  }

  @Action(FetchShipmentsByIds)
  fetchShipmentsByIds({ patchState }: StateContext<WizardStateModel>, { shipmentIds }: FetchShipmentsByIds) {
    return this.apollo
      .query({
        query: FETCH_SHIPMENTS_BY_IDS_QUERY,
        variables: {
          ids: shipmentIds,
        },
      })
      .pipe(
        tap(response => {
          const shipments = response.data['findShipmentsByIds'];
          patchState({
            shipments: shipments.reduce((acc, shipment) => {
              acc[shipment._id] = shipment;
              return acc;
            }, {}),
          });
        }),
        catchError(async err => console.error('Error fetching shipments', err))
      );
  }

  @Action(SelectShipment)
  selectShipment({ patchState, getState }: StateContext<WizardStateModel>, { shipmentId }: SelectShipment) {
    patchState({
      selectedServicesByShipmentId: {
        ...getState().selectedServicesByShipmentId,
        [shipmentId]: new Array<string>(),
      },
    });
  }

  @Action(DeselectShipment)
  deselectShipment({ patchState, getState }: StateContext<WizardStateModel>, { shipmentId }: DeselectShipment) {
    const updatedServices = { ...getState().selectedServicesByShipmentId };
    delete updatedServices[shipmentId];
    patchState({ selectedServicesByShipmentId: updatedServices });
  }

  @Action(SelectService)
  selectService({ patchState, getState }: StateContext<WizardStateModel>, { shipmentId, serviceId }: SelectService) {
    const shipment = { ...getState().selectedServicesByShipmentId };
    shipment[shipmentId] = [...shipment[shipmentId], serviceId];
    patchState({ selectedServicesByShipmentId: shipment });
  }

  @Action(DeselectService)
  deselectService(
    { patchState, getState }: StateContext<WizardStateModel>,
    { shipmentId, serviceId }: DeselectService
  ) {
    const shipment = { ...getState().selectedServicesByShipmentId };
    shipment[shipmentId] = shipment[shipmentId].filter(id => id !== serviceId);
    patchState({ selectedServicesByShipmentId: shipment });
  }

  @Action(SelectOrder)
  selectOrder({ patchState, getState }: StateContext<WizardStateModel>, { orderId }: SelectOrder) {
    const selectedOrder = getState().orders[orderId];
    patchState({
      selectedOrder,
      orderDocuments: {},
    });
  }

  @Action(Set4PLCustomer)
  set4PLCustomer({ patchState }: StateContext<WizardStateModel>, { fourPLCustomer }: Set4PLCustomer) {
    patchState({
      selectedFourPLCustomer: fourPLCustomer,
    });
  }

  @Action(Set4PlContract)
  set4PLContact({ patchState }: StateContext<WizardStateModel>, { fourPlContract }: Set4PlContract) {
    patchState({
      selectedFourPlContract: fourPlContract,
    });
  }

  @Action(Fetch4PLCustomers)
  reset4PLContractRelatedData({ patchState }: StateContext<WizardStateModel>) {
    patchState({
      fourPLCustomers: [],
      fourPlContracts: [],
      selectedFourPLCustomer: null,
      selectedFourPlContract: null,
    });
  }

  @Action(Fetch4PLCustomers)
  fetch4PLCustomers({ patchState }: StateContext<WizardStateModel>) {
    const {
      user: { orgId },
    } = this.store.selectSnapshot(s => s.auth);
    const query = gql`
      query findSmartRateCardByRateCardTypeAndOrgId($rateCardType: String!, $orgId: String!) {
        findSmartRateCardByRateCardTypeAndOrgId(rateCardType: $rateCardType, orgId: $orgId) {
          _id
          contractedPartyId
          contractedParty {
            _id
            orgName
          }
        }
      }
    `;
    const variables = {
      rateCardType: '4PL',
      orgId,
    };

    return this.apollo.query({ query, variables }).pipe(
      tap(response => {
        const fourPLCustomers = response.data['findSmartRateCardByRateCardTypeAndOrgId'];
        patchState({
          fourPLCustomers,
        });
      }),
      catchError(err => {
        console.error('Error fetching 4PL customers', err);
        return of(null);
      })
    );
  }

  @Action(Fetch4PLContracts)
  fetch4PLContracts({ patchState }: StateContext<WizardStateModel>) {
    const query = gql`
      query {
        find4PLCustomers {
          _id
          customerName
          customerId
        }
      }
    `;
    const variables = {};

    return this.apollo.query({ query, variables }).pipe(
      tap(response => {
        const fourPlContracts = response.data['find4PLCustomers'];
        patchState({
          fourPlContracts,
        });
      }),
      catchError(err => {
        console.error('Error fetching 4PL customers', err);
        return of(null);
      })
    );
  }

  // Add new action to update order documents
  @Action(UploadOrderDocument)
  updateOrderDocuments(
    { patchState, getState }: StateContext<WizardStateModel>,
    { category, file }: UploadOrderDocument
  ) {
    patchState({
      orderDocuments: {
        ...getState().orderDocuments,
        [category]: [...(getState().orderDocuments[category] || []), file],
      },
    });
  }

  @Action(UpdateActivity)
  updateActivity({ patchState }: StateContext<WizardStateModel>, { activity, plannedServiceId }: UpdateActivity) {
    return this.apollo
      .mutate({
        mutation: UPDATE_ACTIVITY_MUTATION,
        variables: {
          updateActivityInput: {
            activity,
            plannedServiceId,
          },
        },
      })
      .pipe(
        tap(response => {
          patchState({
            serviceCardActivities: response.data['updateHmtActivity'].activities,
          });
        }),
        catchError(err => {
          console.error('Error updating activity', err);
          return of(null);
        })
      );
  }

  //doesnt need an action to make the delete because the state is not mutated with this action
  // written as an action for the sake of consistency
  @Action(RemoveShipmentWithReference)
  removeShipmentWithReference(
    { getState }: StateContext<WizardStateModel>,
    { shipmentId }: RemoveShipmentWithReference
  ) {
    return this.apollo
      .mutate({
        mutation: REMOVE_SHIPMENT_WITH_REFERENCE_MUTATION,
        variables: {
          shipmentId,
          jobRefId: getState().jobFile?.jobRefId,
        },
      })
      .pipe(
        tap(response => {
          console.log('Shipment removed with reference', response);
        }),
        catchError(err => {
          console.error('Error removing shipment with reference', err);
          return of(null);
        })
      );
  }

  @Action(DeleteOrder)
  deleteOrder({ patchState, getState }: StateContext<WizardStateModel>, { orderId }: DeleteOrder) {
    const jobRefId = getState().jobFile?.jobRefId;
    return this.apollo
      .mutate({
        mutation: DELETE_ORDER_TAB_ORDER_MUTATION,
        variables: {
          removeOrderWithReferenceId: orderId,
          jobRefId: jobRefId,
        },
      })
      .pipe(
        tap(response => {
          console.log('Order deleted', response);
        }),
        catchError(err => {
          console.error('Error deleting order', err);
          return of(null);
        })
      );
  }

  @Action(FetchPaginatedCategorizedOrders)
  fetchPaginatedCategorizedOrders(
    { patchState, getState }: StateContext<WizardStateModel>,
    { jobRefId, shipmentId, selectedOrderId, offset, limit, searchText }: FetchPaginatedCategorizedOrders
  ) {
    return this.apollo
      .query({
        query: PAGINATED_ORDERS_WITH_VALIDATION_QUERY,
        variables: { jobRefId, shipmentId, selectedOrderId, offset, limit, filter: searchText },
      })
      .pipe(
        tap(response => {
          const ordersPageable = response.data['paginatedOrdersWithValidation'];
          patchState({
            categorizedOrders: {
              ...getState().categorizedOrders,
              [shipmentId]: ordersPageable,
            },
          });
        }),
        catchError(err => {
          console.error('Error fetching categorized orders', err);
          return of(null);
        })
      );
  }

  @Action(ResetWizard)
  resetWizard({ patchState }: StateContext<WizardStateModel>) {
    patchState(defaults);
  }

  @Action(UpdateJobSmartenedInJobFile)
  updateJobSmartenedInJobFile(
    { patchState, getState }: StateContext<WizardStateModel>,
    { jobRefId, smartened }: UpdateJobSmartenedInJobFile
  ) {
    return this.apollo
      .mutate({
        mutation: UPDATE_JOB_SMARTENED_IN_JOB_FILE_MUTATION,
        variables: {
          updateJobSmartenedInJobFile: { jobRefId, smartened },
        },
      })
      .pipe(
        tap(response => {
          const updatedJobFile = response.data['updateJobSmartenedInJobFile'];
          patchState({
            jobFile: {
              ...getState().jobFile,
              smartened: updatedJobFile.smartened,
            },
          });
        }),
        catchError(err => {
          console.error('Error updating job smartened', err);
          return of(null);
        })
      );
  }

  @Action(AddHmtActivity)
  addHmtActivity(
    { patchState }: StateContext<WizardStateModel>,
    { activity, plannedServiceId, previousActivityId }: AddHmtActivity
  ) {
    return this.apollo
      .mutate({
        mutation: ADD_HMT_ACTIVITY_MUTATION,
        variables: {
          addActivityInput: { activity, plannedServiceId, previousActivityId },
        },
      })
      .pipe(
        tap(response => {
          const updatedActivities = response.data['addHmtActivity'].activities;
          patchState({
            serviceCardActivities: updatedActivities,
          });
        }),
        catchError(err => {
          console.error('Error adding activity', err);
          return of(null);
        })
      );
  }

  @Action(RemoveHmtActivity)
  removeHmtActivity(
    { patchState }: StateContext<WizardStateModel>,
    { activityId, plannedServiceId }: RemoveHmtActivity
  ) {
    return this.apollo
      .mutate({
        mutation: REMOVE_HMT_ACTIVITY_MUTATION,
        variables: { removeHmtActivityInput: { id: activityId, plannedServiceId } },
      })
      .pipe(
        tap(response => {
          const updatedActivities = response.data['removeHmtActivity'].activities;
          patchState({
            serviceCardActivities: updatedActivities,
          });
        }),
        catchError(err => {
          console.error('Error removing activity', err);
          return of(null);
        })
      );
  }

  @Action(RemoveServiceOption)
  removeServiceOption({ patchState }: StateContext<WizardStateModel>, { shipmentId, optionId }: RemoveServiceOption) {
    return this.apollo
      .mutate({
        mutation: REMOVE_SERVICE_OPTION_MUTATION,
        variables: { shipmentId, optionId },
      })
      .pipe(
        tap(response => {
          console.log('Service option removed', response);
        }),
        catchError(err => {
          console.error('Error removing service option', err);
          return of(null);
        })
      );
  }

  @Action(ResetOrderDocuments)
  resetOrderDocuments({ patchState }: StateContext<WizardStateModel>) {
    patchState({
      orderDocuments: {},
    });
  }

  @Action(RemoveOrderFromShipment)
  removeOrderFromShipment(
    { patchState, getState }: StateContext<WizardStateModel>,
    { shipmentId, orderId }: RemoveOrderFromShipment
  ) {
    return this.graphqlClient
      .mutation(REMOVE_ORDER_FROM_SHIPMENT_MUTATION, { shipmentId, orderId, jobRefId: getState().jobFile?.jobRefId })
      .pipe(
        tap(response => {
          console.log('Order removed from shipment', response);
        }),
        catchError(err => {
          console.error('Error removing order from shipment', err);
          return of(null);
        })
      );
  }

  @Action(DuplicateServiceOption)
  duplicateServiceOption(
    { patchState }: StateContext<WizardStateModel>,
    { shipmentId, serviceOptionId }: DuplicateServiceOption
  ) {
    return this.graphqlClient
      .mutation<
        { duplicateServiceOption },
        { duplicateServiceOptionInput: { shipmentId: string; serviceOptionId: string } }
      >(DUPLICATE_SERVICE_OPTIONS_MUTATION, { duplicateServiceOptionInput: { shipmentId, serviceOptionId } })
      .pipe(
        tap(response => {
          console.log('Service option duplicated', response);
        }),
        catchError(err => {
          console.error('Error duplicating service option', err);
          return of(null);
        })
      );
  }

  @Action(UpdateServiceCard)
  updateServiceCard({ patchState }: StateContext<WizardStateModel>, { serviceCard }: UpdateServiceCard) {
    patchState({
      serviceCard,
    });
    patchState({
      serviceCardActivities: serviceCard?.activities ?? [],
    });
  }

  @Action(RemoveServiceCard)
  removeServiceCard({ patchState }: StateContext<WizardStateModel>, { serviceCardId }: RemoveServiceCard) {
    return this.graphqlClient
      .mutation<
        { removeServiceCard },
        { removeServiceCardId: string }
      >(REMOVE_SERVICE_CARD_MUTATION, { removeServiceCardId: serviceCardId })
      .pipe(
        tap(response => {
          console.log('Service card removed', response);
        }),
        catchError(err => {
          console.error('Error removing service card', err);
          return of(null);
        })
      );
  }

  static setNestedValue(obj, path, value) {
    const keys = path.split('.');
    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        if (!acc[key]) acc[key] = {};
      }
      return acc[key];
    }, obj);
  }

  static createNestedObjectFromPaths(results, newPaths) {
    const nestedObject = {};
    newPaths.forEach((path, index) => {
      WizardState.setNestedValue(nestedObject, path, results[index].value);
    });
    return nestedObject;
  }

  static getValueFromPath(object, path) {
    return path.split('.').reduce((o, key) => o && o[key], object);
  }

  static getValuesFromPaths(object, paths) {
    return paths.map(path => ({
      path: path,
      value: WizardState.getValueFromPath(object, path)?.value ?? WizardState.getValueFromPath(object, path),
    }));
  }

  static replacePaths(results, newPaths) {
    return results.map((result, index) => ({
      path: newPaths[index],
      value: result.value,
    }));
  }
}
