import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, filter, map } from 'rxjs';

export type OneOfScopesConfigPartial = {
  oneOf: string[];
};

export type AllOfScopesConfigPartial = {
  allOf: string[];
};

export type AndScopesConfigPartial =
  | {
      and: [OneOfScopesConfigPartial, OneOfScopesConfigPartial];
    }
  | {
      and: [AllOfScopesConfigPartial, AllOfScopesConfigPartial];
    }
  | {
      and: [OneOfScopesConfigPartial, AllOfScopesConfigPartial];
    }
  | {
      and: [AllOfScopesConfigPartial, OneOfScopesConfigPartial];
    };

export type OrScopesConfigPartial =
  | {
      or: [OneOfScopesConfigPartial, OneOfScopesConfigPartial];
    }
  | {
      or: [AllOfScopesConfigPartial, AllOfScopesConfigPartial];
    }
  | {
      or: [OneOfScopesConfigPartial, AllOfScopesConfigPartial];
    }
  | {
      or: [AllOfScopesConfigPartial, OneOfScopesConfigPartial];
    };

export type RouteScopeConfig =
  | OneOfScopesConfigPartial
  | AllOfScopesConfigPartial
  | AndScopesConfigPartial
  | OrScopesConfigPartial;

const matchScopes = (userScope: string, routeScope: string): boolean => {
  const [userPrefix, userAction] = userScope.split(':');
  const [routePrefix, routeAction] = routeScope.split(':');
  return userPrefix === routePrefix && (userAction === 'any' || routeAction === 'any' || userAction === routeAction);
};

export const getUsersSystemsWideAllowedActions = (userScopes: string[]): string[] => {
  const usersSystemWideScopes = userScopes.filter(scope => scope.startsWith('systems_wide:'));
  if (usersSystemWideScopes.includes('systems_wide:any')) {
    return ['any', 'create', 'read', 'update', 'delete'];
  }
  const actionsOfUserSystemWideScopes = usersSystemWideScopes.map(scope => scope.split(':')[1]);
  return actionsOfUserSystemWideScopes;
};

export const isActionValidSystemWide = (action: string, usersSystemWideAllowedActions: string[]) => {
  // MAKING SURE THAT SUPER ACTION IS NOT VALID SYSTEM WIDE
  return (
    action !== 'super' &&
    (usersSystemWideAllowedActions.includes(action) || usersSystemWideAllowedActions.includes('any'))
  );
};

const checkOneOfScopes = (
  userScopes: string[],
  config: OneOfScopesConfigPartial,
  usersSystemWideAllowedActions: string[]
): boolean => {
  return config.oneOf.some(
    routeScope =>
      userScopes.some(userScope => matchScopes(userScope, routeScope)) ||
      isActionValidSystemWide(routeScope.split(':')[1], usersSystemWideAllowedActions)
  );
};

const checkAllOfScopes = (
  userScopes: string[],
  config: AllOfScopesConfigPartial,
  usersSystemWideAllowedActions: string[]
): boolean => {
  return config.allOf.every(
    routeScope =>
      userScopes.some(userScope => matchScopes(userScope, routeScope)) ||
      isActionValidSystemWide(routeScope.split(':')[1], usersSystemWideAllowedActions)
  );
};

const checkAndScopes = (
  userScopes: string[],
  config: AndScopesConfigPartial,
  usersSystemWideAllowedActions: string[]
): boolean => {
  return config.and.every(scopeConfig => {
    if ('oneOf' in scopeConfig) {
      return checkOneOfScopes(userScopes, scopeConfig, usersSystemWideAllowedActions);
    } else if ('allOf' in scopeConfig) {
      return checkAllOfScopes(userScopes, scopeConfig, usersSystemWideAllowedActions);
    }
    return false;
  });
};

const checkOrScopes = (
  userScopes: string[],
  config: OrScopesConfigPartial,
  usersSystemWideAllowedActions: string[]
): boolean => {
  return config.or.some(scopeConfig => {
    if ('oneOf' in scopeConfig) {
      return checkOneOfScopes(userScopes, scopeConfig, usersSystemWideAllowedActions);
    } else if ('allOf' in scopeConfig) {
      return checkAllOfScopes(userScopes, scopeConfig, usersSystemWideAllowedActions);
    }
    return false;
  });
};

export function checkScopes(routeScopes: RouteScopeConfig) {
  const store = inject(Store);
  const userScopes = store.selectSnapshot(AuthState.getUserScopes);
  const usersSystemWideAllowedActions = getUsersSystemsWideAllowedActions(userScopes);

  if (!routeScopes) {
    return true; // If no scopes are specified, allow access
  }

  if ('oneOf' in routeScopes) {
    return checkOneOfScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
  } else if ('allOf' in routeScopes) {
    return checkAllOfScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
  } else if ('and' in routeScopes) {
    return checkAndScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
  } else if ('or' in routeScopes) {
    return checkOrScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
  }

  return false;
}

export function checkScopesReactive(routeScopes: RouteScopeConfig) {
  const store = inject(Store);
  return store.select(AuthState.getUserScopes).pipe(
    filter(userScopes => userScopes && userScopes.length > 0),
    map(userScopes => {
      const usersSystemWideAllowedActions = getUsersSystemsWideAllowedActions(userScopes);
      if (!routeScopes) {
        return true; // If no scopes are specified, allow access
      }

      if ('oneOf' in routeScopes) {
        return checkOneOfScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
      } else if ('allOf' in routeScopes) {
        return checkAllOfScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
      } else if ('and' in routeScopes) {
        return checkAndScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
      } else if ('or' in routeScopes) {
        return checkOrScopes(userScopes, routeScopes, usersSystemWideAllowedActions);
      }

      return false;
    }),
    distinctUntilChanged()
  );
}

export const authGuard: CanActivateFn = route => {
  const routeScopes = route.data['scopes'] as RouteScopeConfig;
  const result = checkScopes(routeScopes);
  return result;
};
